// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-company-cases-index-js": () => import("./../../../src/pages/company-cases/index.js" /* webpackChunkName: "component---src-pages-company-cases-index-js" */),
  "component---src-pages-dato-cms-article-slug-js": () => import("./../../../src/pages/{datoCmsArticle.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-article-slug-js" */),
  "component---src-pages-dato-cms-company-case-slug-js": () => import("./../../../src/pages/{datoCmsCompanyCase.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-company-case-slug-js" */),
  "component---src-pages-dato-cms-tech-talent-slug-js": () => import("./../../../src/pages/{datoCmsTechTalent.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-tech-talent-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tech-talents-index-js": () => import("./../../../src/pages/tech-talents/index.js" /* webpackChunkName: "component---src-pages-tech-talents-index-js" */)
}

