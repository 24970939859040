import 'intersection-observer';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/700.css';
import './src/styles/buttons.css';
import './src/styles/global.css';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import NewsletterPopup from './src/components/NewsletterPopup';

// Fixes on "Back" saving scroll position
export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);
  return false;
};

console.log('Howdy fellow nerd, hope you are having a mighty fine day! 🙌 🚀 🎉');

TagManager.initialize({
  gtmId: 'GTM-WXHNSDL',
});

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <NewsletterPopup />
  </>
);
