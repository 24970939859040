import React, { useEffect, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { cn, validateEmail } from '../utils';
import '../styles/newsletter-popup.css';

const NewsletterPopup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [fieldsValid, setFieldsValid] = useState();
  const [iConsent, setIConsent] = useState(false);
  const [formStatus, setFormStatus] = useState();
  const [error, setError] = useState(false);
  const [openNewsletterForm, setOpenNewsletterForm] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    let counter = 0;
    if (firstName.length > 0) counter += 1;
    if (lastName.length > 0) counter += 1;
    if (jobTitle.length > 0) counter += 1;
    if (validateEmail(email)) counter += 1;
    // if (iConsent) counter += 1
    setFieldsValid(counter);
    setError(false);
  }, [firstName, lastName, jobTitle, email, iConsent]);

  const onSubmit = async () => {
    let fields = {
      FIRSTNAME: firstName,
      LASTNAME: lastName,
      JOBTITLE: jobTitle,
    };

    const response = await addToMailchimp(email, fields);

    if (response.result === 'success') setFormStatus(response.result);
    if (response.result === 'error') setError(response);
  };

  useEffect(() => {
    if (!popup) {
      setTimeout(() => {
        setPopup(true);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && formStatus === 'success') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newsletterSubmissionCompleted',
      });
    }
  }, [formStatus]);

  if (!closePopup) {
    return (
      <div
        className={cn(
          'newsletter-popup',
          openNewsletterForm && 'newsletter-popup-opened',
          popup && 'newsletter-popup-slide-up'
        )}
      >
        <div className="newsletter-popup-button">
          <button className="newsletter-popup-x" onClick={() => setClosePopup(true)}>
            X
          </button>
          <button onClick={() => setOpenNewsletterForm(true)}>
            <p className="newsletter-popup-title">Stay Updated 🚀</p>
          </button>
        </div>
        <div className="newsletter-popup-form">
          <button className="newsletter-popup-x" onClick={() => setOpenNewsletterForm(false)}>
            X
          </button>
          {formStatus === 'success' && (
            <>
              <h1 tw="text-xl text-green font-bold mb-4">Welcome! 🎉</h1>
              <p tw="mb-2">
                We look forward to having you on board & deliver relevant updates to your inbox! 📬
              </p>
            </>
          )}
          {!formStatus && (
            <>
              <p className="newsletter-popup-title">Stay Updated 🚀</p>
              <p>
                Sign up to our newsletter and stay up to date on the latest news, insights and
                events from the Danish cutting-edge tech scene.
              </p>
              <div>
                {error && <p dangerouslySetInnerHTML={{ __html: error.msg }} />}
                <div>
                  <Input
                    id="mce-FNAME"
                    value={firstName}
                    label="First Name"
                    onChange={setFirstName}
                  />
                  <Input
                    id="mce-MMERGE2"
                    value={lastName}
                    label="Last Name"
                    onChange={setLastName}
                  />
                </div>
                <Input id="mce-MMERGE3" value={jobTitle} label="Job Title" onChange={setJobTitle} />
                <Input id="mce-EMAIL" value={email} label="Email" onChange={setEmail} />
                <div>
                  <label htmlFor="consent">
                    <div
                      id="consent"
                      onClick={() => setIConsent((val) => !val)}
                      role="button"
                      aria-label="consent"
                      tabIndex="0"
                      onKeyPress={() => setIConsent((val) => !val)}
                    />
                  </label>
                  <button
                    className="newsletter-popup-submit-button"
                    onClick={() => onSubmit()}
                    disabled={fieldsValid !== 4}
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  return null;
};

const Input = ({ id, label, onChange, type, value }) => {
  const [inFocus, setInFocus] = useState();
  const completed = value?.length > 0 && !inFocus;

  return (
    <label htmlFor="mce-MMERGE3">
      <span>{label}</span>
      <input
        id={id}
        name={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type || 'text'}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus()}
      />
    </label>
  );
};

export default NewsletterPopup;
