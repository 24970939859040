export const cn = (...args) => args.filter(Boolean).join(' ');

export const truncate = (str, max, suffix) => {
  if (!str) return null;
  const firstSentence = `${str.split('. ')[0]}.`;
  return firstSentence.length < max
    ? firstSentence
    : `${firstSentence.substr(
        0,
        firstSentence.substr(0, max - suffix.length).lastIndexOf(' ')
      )}${suffix}`;
};

export const createDescription = (content) => {
  if (!content) return null;
  const firstSentence = content
    ?.split('</p>')[0]
    ?.split('>')[1]
    ?.replace(/&nbsp;/g, ' ')
    ?.replace(/&ndash;/g, '&')
    ?.replace(/&ldquo;/g, '"')
    ?.replace(/&rsquo;/g, "'");
  return truncate(firstSentence, 155, '...');
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const stringifyArray = (arr) => {
  let str = '';
  let i = 0;
  while (i < arr.length) {
    const val = arr[i].title || arr[i].name;
    if (i === 0) {
      str += `${val}`;
    } else if (i === arr.length - 1) {
      str += ` & ${val}`;
    } else {
      str += `, ${val}`;
    }
    i += 1;
  }
  return str;
};

export const isOldTowardsNo1Post = (name, sortDate) => {
  return name === 'Article' && sortDate < 20210119;
};
